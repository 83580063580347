import { useCartWithTimeslot } from '~/src/common/hooks/use-cart-with-timeslot';
import { useScreenSize } from '~/src/common/hooks/use-screen-size';

import CenterContent from './CenterContent';
import HeaderButtons from './HeaderButtons';
import useSearchAction from './hooks/use-search-action';
import * as S from './layout';
import LeftContent from './LeftContent';

const Navigation = () => {
  useCartWithTimeslot();
  const { isSearchOpen, searchContainerRef, handleSearchClick, setSearchOpened } =
    useSearchAction();

  const { isLargeScreen } = useScreenSize();

  return (
    <S.StickyContainer ref={searchContainerRef}>
      <S.CommonContainer>
        <S.LeftContainer>
          <LeftContent />
        </S.LeftContainer>

        <S.CenterContainer>
          <CenterContent isSearchOpen={isSearchOpen} setSearchOpened={setSearchOpened} />
        </S.CenterContainer>

        <S.RightContainer>
          <HeaderButtons onSearchClick={handleSearchClick} />
        </S.RightContainer>
      </S.CommonContainer>

      {!isLargeScreen ? <S.PageSpecificContainer id="header-mobile-content" /> : null}
    </S.StickyContainer>
  );
};

export default Navigation;
