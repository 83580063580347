import { useEffect } from 'react';

import { useQueryClient } from '@tanstack/react-query';

import { getNewTimeSlot } from '~/src/common/hooks/cart/utils';
import { Cart } from '~/src/common/typings/cart';
import { useGetCart } from '~/src/queries/api-ecom/generated/api-ecom';

export const useCartWithTimeslot = () => {
  const { data: cart } = useGetCart<Cart>();

  // quand on recupère un nouveau panier, on vérifie si le créneau est toujours valide
  const QueryClient = useQueryClient();
  useEffect(() => {
    const timeSlotValidity = cart?.delivery?.timeSlotValidity;
    if (cart != null && (timeSlotValidity === 'invalid' || timeSlotValidity === 'deleted')) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises  -- auto-ignored when updating eslint
      getNewTimeSlot(QueryClient, cart);
    }
  }, [QueryClient, cart]);
};
